// https://tailwindcss.com/docs/dark-mode#toggling-dark-mode-manually

import { isRunningOnWeb } from "./utils";

/**
 * 
 * @returns 
 */
export const isDarkThemeOnUse = () => {
  return isRunningOnWeb() && (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) ? true : false;
}

/**
 * Controlla il theme corrente e aggiunge o toglie la classe dark al tag html
 */
export const checkTheme = () => {
  if (isDarkThemeOnUse()) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}

/**
 * 
 * @param theme 
 */
export const setTheme = (theme: 'light' | 'dark') => {
  if (isRunningOnWeb())
    localStorage.theme = theme;
  checkTheme();
}

/**
 * 
 */
export const getTheme = (): 'light' | 'dark' => {
  return isDarkThemeOnUse() ? 'dark' : 'light';
}

/**
 * 
 */
export const useOSTheme = () => {
  if (isRunningOnWeb())
    localStorage.removeItem('theme')
  checkTheme();
}

export const isMobile = () => {
  const userAgent: string = isRunningOnWeb() && typeof window.navigator !== "undefined" ? navigator.userAgent : "";
  const isMobile: boolean = Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
  return isMobile;
}
